<div class="modal-header">
    <h5 class="fw-bold pt-4 ps-4">
        <span class="text-uppercase ms-3">Agregar Validación</span>
    </h5>
    <button type="button" class="btn-close mt-4 me-4" mat-dialog-close></button>
</div>

<mat-dialog-content class="mat-typography">
    <div class="modal-body">
        <div class="container">

            <section>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedOption">
                    <mat-radio-button value="1">Numérico</mat-radio-button>
                    <mat-radio-button class="ms-4" value="2">URL</mat-radio-button>
                    <mat-radio-button class="ms-4" value="3">Arreglo</mat-radio-button>
                </mat-radio-group>
            </section>
    
            <section *ngIf="selectedOption === '3'" class="mt-2">
                <mat-form-field class="chip-list" appearance="fill">
                    <mat-label>Agregar opción: </mat-label>
                    <mat-chip-grid #chipGrid>
                        <mat-chip-row *ngFor="let option of options" (removed)="removeChip(option)">
                            {{option}}
                            <button matChipRemove [attr.aria-label]="'remove ' + option">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                        
                        <input placeholder="Nueva opción..."
                                [matChipInputFor]="chipGrid"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="addChip($event)"/>
                    </mat-chip-grid>
                </mat-form-field>
            </section>

            <section class="mt-4">
                <mat-checkbox [(ngModel)]="isUnique" class="">¿Es único?</mat-checkbox>
            </section>
    
            <section class="mt-4">
                <mat-form-field class="">
                    <mat-label>Longitud Mínima: </mat-label>
                    <input [(ngModel)]="minLength" matInput placeholder="" value="">
                </mat-form-field>
                <mat-form-field class="">
                    <mat-label>Longitud Máxima: </mat-label>
                    <input [(ngModel)]="maxLength" matInput placeholder="" value="">
                </mat-form-field>
            </section>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="idValidation" mat-flat-button class="bg-danger text-white" (click)="deleteSettings()" type="button">Limpiar <mat-icon>delete</mat-icon></button>
        <button mat-flat-button class="bg-success text-white ms-3" (click)="saveSettings()" type="button">Guardar <mat-icon>save</mat-icon></button>
    </div>
</mat-dialog-content>


