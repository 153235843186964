import { Component, Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-validation-qr',
  templateUrl: './validation-qr.component.html',
  styleUrls: ['./validation-qr.component.scss']
})
export class ValidationQrComponent {

  selectedOption: string = '';
  isUnique: boolean = false;
  minLength: string = ''; 
  maxLength: string = '';
  options: string[] = [];
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  idValidation = null;

  constructor(
    public dialogRef: MatDialogRef<ValidationQrComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.selectedOption = data.tipo_validacion || '';
      this.options = data.arreglo?.split(",") || [];
      this.isUnique = data.bitUnico || 0;
      this.minLength = data.min_length || '';
      this.maxLength = data.max_length || '';
      this.idValidation = data.idValidation || 0;
    }
  

  addChip(event: any): void {
    const value = (event.value || '').trim();
    if (value) {
      this.options.push(value);
    }
    event.chipInput!.clear();
  }

  removeChip(option: string): void {
    const index = this.options.indexOf(option);
    if (index >= 0) {
      this.options.splice(index, 1);
    }
  }

  saveSettings(): void {
    if (this.selectedOption) {
      this.dialogRef.close({ option: this.selectedOption, unico: this.isUnique, minLength: this.minLength, maxLength: this.maxLength, arreglo: this.options, delete: 0 });
    } else {
      this.dialogRef.close(null);
    }
  }

  deleteSettings(): void {
    if (this.selectedOption) {
      this.dialogRef.close({ idValidation: this.idValidation, delete: 1 });
    } else {
      this.dialogRef.close(null);
    }
  }

}
